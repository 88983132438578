@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.fa{
color: blue;
}

.middle_part{
    display: flex;
    width: 90vw;
    margin: 20px auto;
    font-family: 'Poppins', sans-serif;
    position: relative;
}

.img_container{
   width: 100%;
   height: 50vh;
   /* border: 2px solid green; */
   margin-bottom: 20px;
}

.img_container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.middle_part_Text{
    width: 100%;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 20px;

}

.middle_part_Text h1{
    font-size: 30px;
    font-weight: 900;
   
}

.middle_part p{
    width: 100%;
    /* height: 50px; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 20px;
}

.middle_part .fa{
    margin: 0px 20px;
}

Button{
    height: 50px;
}

@media screen and  (max-width:900px) {
    .middle_part{
        flex-direction: column;
    }
}