.Footer{
    background: url(../images/background_img.png);
    color: white;
    text-align: center;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.Footer h1{
    margin: 20px 0px;
}

.Footer p{
    margin: 20px 0px;
}

.btn{
    height: 10vh;
    max-width: 500px;
    min-width: 300px;
    width: 90%;
    font-size: 1.5rem;
    text-align: center;
}

.Footer a{
    text-decoration: none;
    color: white;
}

.Footer a:hover{
    color: white;
}
@media screen and (max-width:900px){
    .btn{
        font-size: 0.8rem;
    }

    .Footer{
        padding: 20px 10px;
    }
}



