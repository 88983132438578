#thanks{
    position:absolute;
    top:0;
    left: 0;
    /* border: 2px solid red; */
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 40vh;
    font-size: 40px;
    font-weight: 900;
    background-color: white;
}

.hidden{
    display: none;
}