*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.Timer{
    display: flex;
    /* border: 2px solid red; */
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: 20px auto;
}

.Time-container{
    margin: 20px 20px;
   width: 100px;
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
   
}

.Time-container p{
    font-size: 10px;
    font-weight: 800;
    color: rgb(39, 39, 39);
    margin-top: 5px;
}

.hour,
.minute,
.second{
    border: 5px solid black;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    
   
}

.hour > h1,
.minute > h1,
.second >h1{
    font-size: 15px;
    font-weight: 900;
    /* border: 2px solid yellow; */
    margin: 20px auto;
}
