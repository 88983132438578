#example-collapse-text{
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
    /* border: 2px solid red; */
    /* width: 50vw; */
    /* height: 50vh; */
    border-radius: 22px;
    background-color: white;
}

.example-collapse-text-upper{
    background-color: #21B6D7;
    /* height: 10vh; */
    /* width: 100%; */
    border-radius: 22px 22px 0 0;
    
}
.example-collapse-text-upper h1{
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
}

.fa-close{
    position: absolute;
    top: 0;
    right: 0;
    color: black !important;
}
.form-img-container{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
    margin: 10px auto;
    /* position: absolute;
    top: -10%;
    left:50%;
    transform: translate(-50%,0); */
    

}

.form-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
    
form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid red; */
    margin: 50px 0;
}

form > input {
    width: 80%;
    border: none;
    border-radius: 22px;
    background: rgb(247, 247, 247);
    height: 5vh;
    text-align: center;

}

input[type="submit"]{
    background-color: rgb(60, 60, 255);
    color: white;
}
@media screen and (max-width:900px) {
   
    #example-collapse-text{
        width: 90vw;
    }
}
