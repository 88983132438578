
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.Hero{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 50px;
    position: relative;
}

.Hero_red{
    color: red;
}

.Hero_text{
    font-size: 35px;
    max-width: 750px;
    margin:  20px auto;
}

.Hero_subtext{
    font-size: 20px;
    margin: 20px auto;
}

h1 > span {
    font-weight: 900;
}

.Hero__p{
    font-size: 10px;
}

#example-collapse-text{
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
    /* border: 2px solid red; */
    /* width: 50vw; */
    /* height: 50vh; */
    border-radius: 22px;
    background-color: white;
}

.example-collapse-text-upper{
    background-color: #21B6D7;
    /* height: 10vh; */
    /* width: 100%; */
    border-radius: 22px 22px 0 0;
    
}
.example-collapse-text-upper h1{
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
}

.fa-close{
    position: absolute;
    top: 0;
    right: 0;
    color: black !important;
}
.form-img-container{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
    margin: 10px auto;
    /* position: absolute;
    top: -10%;
    left:50%;
    transform: translate(-50%,0); */
    

}

.form-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
    
form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid red; */
    margin: 50px 0;
}

form > input {
    width: 80%;
    border: none;
    border-radius: 22px;
    background: rgb(247, 247, 247);
    height: 5vh;
    text-align: center;

}

input[type="submit"]{
    background-color: rgb(60, 60, 255);
    color: white;
}
@media screen and (max-width:900px) {
    .Hero{
        padding: 20px;
    }
    .Hero_text{
        margin: 1px auto;
        font-size: 20px;
        max-width: 100vw;
    }

    #example-collapse-text{
        width: 90vw;
    }
}
